<i18n>
ru:
  cancel: Отменить
  success: Адрес сохранён!
  title: Добавить адрес доставки
  yourAddress: 'Ваш адрес:'
ua:
  cancel: Скасувати
  success: Адреса збережено!
  title: Додати адресу доставки
  yourAddress: 'Ваша адреса:'
us:
  cancel: Cancel
  success: Address saved!
  title: Add delivery address
  yourAddress: 'Your address:'
</i18n>

<template>
  <div class="v-row v-mb-sm">
    <div
      class="v-popup-head v-title v-col-12 v-saved-address-popup-title"
      v-html="translate('savedAddressesPopup.title')"
    />
  </div>
  <div class="v-w-100">
    <address-delivery-city-district
      :delivery-address="newAddress"
      :form="validatorForm"
      :save-delivery-address="setAddressFields"
    />
  </div>
  <div class="v-row v-mb-sm">
    <div
      class="v-col-12 v-font-weight-600"
      v-html="translate('savedAddressesPopup.yourAddress')"
    />
  </div>
  <div class="v-w-100">
    <cart-street-list-autocomplete
      :delivery-address="newAddress"
      :form="validatorForm"
      :save-delivery-address="setAddressFields"
    />
  </div>
  <div class="v-row v-mb-sm">
    <div
      class="v-col-12 v-font-weight-600"
      v-html="translate('addressSelectorPopup.additionalInfo')"
    />
  </div>

  <div
    v-if="orderDeliveryTypes.length > 0"
    class="v-row v-mb-sm"
  >
    <div class="v-col-12">
      <div
        class="v-additional-order-delivery-type v-mb-sm"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.OrderDeliveryType <= 0 ? null : newAddress.OrderDeliveryType,
          Required: true,
          Validations: []
        }"
        data-test-id="additional-order-delivery-type"
      >
        <arora-radio-input
          hide-label
          required
          :as-column="!!isExtraSmall"
          :label="translate('additionalInfo.orderDeliveryTypes')"
          :options="orderDeliveryTypes"
          v-model:selected="newAddress.OrderDeliveryType"
        >
          <template #option="option">
            <span
              v-if="option.value === OrderDeliveryType.Flat"
              data-test-id="order-user-delivery-type-flat"
              v-html="translate('additionalInfo.orderTypeFlat')"
            />
            <span
              v-else-if="option.value === OrderDeliveryType.House"
              data-test-id="order-user-delivery-type-house"
              v-html="translate('additionalInfo.orderTypeHouse')"
            />
            <span
              v-else-if="option.value === OrderDeliveryType.Org"
              data-test-id="order-user-delivery-type-org"
              v-html="translate('additionalInfo.orderTypeOrg')"
            />
          </template>
        </arora-radio-input>
      </div>
    </div>
  </div>

  <transition
    appear
    mode="out-in"
    name="slidedown"
  >
    <div
      v-if="newAddress.OrderDeliveryType === OrderDeliveryType.Flat"
      class="v-row"
      data-test-id="additional-order-delivery-type-flat"
    >
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          required
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Apartment,
            Required: true,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.apartment')"
          data-test-id="order-user-apartment"
          v-model:text="newAddress.Apartment"
        />
      </div>
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Doorway,
            Required: false,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.doorway')"
          data-test-id="order-user-doorway"
          v-model:text="newAddress.Doorway"
        />
      </div>
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Floor,
            Required: false,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.floor')"
          data-test-id="order-user-floor"
          v-model:text="newAddress.Floor"
        />
      </div>
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Intercome,
            Required: false,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.intercom')"
          data-test-id="order-user-intercom"
          v-model:text="newAddress.Intercome"
        />
      </div>
    </div>
    <div
      v-else-if="newAddress.OrderDeliveryType === OrderDeliveryType.Org"
      class="v-row"
      data-test-id="additional-order-delivery-type-org"
    >
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Apartment,
            Required: false,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.office')"
          data-test-id="order-user-office"
          v-model:text="newAddress.Apartment"
        />
      </div>
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Doorway,
            Required: false,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.doorway')"
          data-test-id="order-user-doorway"
          v-model:text="newAddress.Doorway"
        />
      </div>
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Floor,
            Required: false,
            Validations: ['digits', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.floor')"
          data-test-id="order-user-floor"
          v-model:text="newAddress.Floor"
        />
      </div>
      <div class="v-col-6">
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: validatorForm,
            Value: newAddress.Intercome,
            Required: false,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.intercom')"
          data-test-id="order-user-intercom"
          v-model:text="newAddress.Intercome"
        />
      </div>
    </div>
  </transition>

  <div
    v-if="orderDeliveryTypes.length === 0"
    class="v-row v-mb-sm"
  >
    <div
      v-if="!appConfig.VueSettingsPreRun.DoorwayHidden"
      class="v-col-6"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.Doorway,
          Required: appConfig.VueSettingsPreRun.DoorwayRequired,
          Validations: ['noSpace', 'length'],
          MaxLength: 10
        }"
        :label="translate('additionalInfo.doorway')"
        :required="appConfig.VueSettingsPreRun.DoorwayRequired"
        v-model:text="newAddress.Doorway"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.BuildingHidden"
      class="v-col-6"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.Building,
          Required: appConfig.VueSettingsPreRun.BuildingRequired,
          Validations: ['noSpace', 'length'],
          MaxLength: 10
        }"
        :label="translate('additionalInfo.building')"
        :required="appConfig.VueSettingsPreRun.BuildingRequired"
        v-model:text="newAddress.Building"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.ApartmentHidden"
      class="v-col-6"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.Apartment,
          Required: appConfig.VueSettingsPreRun.ApartmentRequired,
          Validations: ['noSpace', 'length'],
          MaxLength: 10
        }"
        :label="translate('additionalInfo.apartment')"
        :required="appConfig.VueSettingsPreRun.ApartmentRequired"
        v-model:text="newAddress.Apartment"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.IntercomHidden"
      class="v-col-6"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.Intercome,
          Required: appConfig.VueSettingsPreRun.IntercomRequired,
          Validations: ['length'],
          MaxLength: 10
        }"
        :label="translate('additionalInfo.intercom')"
        :required="appConfig.VueSettingsPreRun.IntercomRequired"
        v-model:text="newAddress.Intercome"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.FloorHidden"
      class="v-col-6"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.Floor,
          Required: appConfig.VueSettingsPreRun.FloorRequired,
          Validations: ['length'],
          MaxLength: 10
        }"
        :label="translate('additionalInfo.floor')"
        :required="appConfig.VueSettingsPreRun.FloorRequired"
        v-model:text="newAddress.Floor"
      />
    </div>
  </div>
  <div class="v-row">
    <div class="v-col-12">
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.Comment,
          Required: false,
          Validations: ['length'],
          MaxLength: 255
        }"
        :label="translate('comment.comment')"
        v-model:text="newAddress.Comment"
      />
    </div>
  </div>

  <div class="v-row">
    <div class="v-col-12">
      <transition
        appear
        mode="out-in"
        name="slidedown"
      >
        <div
          v-if="fieldsNotValidated"
          class="v-col-12 v-mb-md v-d-flex v-align-items-center"
        >
          <icon-general-information-circle class="v-error-color v-mr-xs" />
          <span
            class="v-error-color"
            v-html="translate('yandexMapsSavedAddressesPopup.notAllFilled')"
          />
        </div>
      </transition>
    </div>
  </div>

  <div class="v-row">
    <div class="v-offset-3 v-offset-md-4 v-col-4">
      <arora-button
        class-name="v-btn v-btn-text v-keep-fz v-no-underline v-w-100 v-h-100"
        ignore-settings
        :label="translate('savedAddressesPopup.cancel')"
        @click="async () => await popupStore.closePopup()"
      />
    </div>

    <div class="v-col-5 v-col-md-4">
      <arora-button
        class-name="v-w-100"
        :disabled="lockButton"
        :label="translate('savedAddressesPage.save')"
        @click="async () => await saveAddress()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TwoStepsSavedAddress } from '~types/accountStore'
import type { SavedAddressInternal } from '~types/addressStore'

import { useValidationStore, useWindowSize, vFormValidator, VInput } from '@arora/common'

import { getActivePinia } from 'pinia'
import { Guid, OrderDeliveryType } from '~api/consts'

const { address } = defineProps<{
  address: TwoStepsSavedAddress | undefined
}>()

const validatorForm = 'savedAddressesPopup'

const defaultAddress = {
  AddressType: '',
  Apartment: '',
  AreaID: Guid.Empty,
  AreaName: '',
  Building: '',
  CityID: Guid.Empty,
  CityName: '',
  CloserDepartmentID: Guid.Empty,
  CloserTerminalID: Guid.Empty,
  Comment: '',
  Corpus: '',
  Doorway: '',
  Floor: '',
  House: '',
  Id: Guid.Empty,
  Intercome: '',
  OrderDeliveryType: 0,
  Street: '',
  ZipCode: ''
}

const newAddress = ref<TwoStepsSavedAddress>({ ...defaultAddress })

function setAddressFields(address: SavedAddressInternal): void {
  if (newAddress.value) {
    if (address.departmentId) newAddress.value.CloserDepartmentID = address.departmentId
    if (address.terminalId) newAddress.value.CloserTerminalID = address.terminalId
    if (address.district) newAddress.value.AreaName = address.district
    if (address.districtId) newAddress.value.AreaID = address.districtId
    if (address.city) newAddress.value.CityName = address.city
    if (address.cityId) newAddress.value.CityID = address.cityId
    if (address.Street) newAddress.value.Street = address.Street
    if (address.House) newAddress.value.House = address.House
    if (Object.hasOwn(address, 'Apartment')) newAddress.value.Apartment = address.Apartment ?? ''
    if (Object.hasOwn(address, 'Corpus')) newAddress.value.Corpus = address.Corpus ?? ''
    if (Object.hasOwn(address, 'Building')) newAddress.value.Building = address.Building ?? ''
    if (Object.hasOwn(address, 'Doorway')) newAddress.value.Doorway = address.Doorway ?? ''
    if (Object.hasOwn(address, 'Intercom')) newAddress.value.Intercome = address.Intercom ?? ''
    if (Object.hasOwn(address, 'Floor')) newAddress.value.Floor = address.Floor ?? ''
    if (address.OrderDeliveryType) {
      newAddress.value.OrderDeliveryType = address.OrderDeliveryType
    }
    if (address.comment) newAddress.value.Comment = address.comment
  }
}

const accountStore = useAccountStore()
const addressStore = useAddressStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const { clean, translate } = useI18nSanitized()
const { isExtraSmall } = useWindowSize()
const appConfig = useAppConfig()

const orderDeliveryTypes = computed<number[]>(() => {
  const result: number[] = []

  if (!appConfig.VueSettingsPreRun.OrderDeliveryTypeEnable) return result

  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowFlat) result.push(OrderDeliveryType.Flat)
  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowHouse) result.push(OrderDeliveryType.House)
  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowOrg) result.push(OrderDeliveryType.Org)

  return result
})

const fieldsNotValidated = ref<boolean>(false)

onMounted(() => {
  addressStore.initCities()

  if (address) newAddress.value = { ...address }
})

const lockButton = ref<boolean>(false)

async function saveAddress(): Promise<void> {
  lockButton.value = true
  try {
    fieldsNotValidated.value = !validationStore.formPassedCheck(validatorForm)
    if (fieldsNotValidated.value) return

    newAddress.value.Street = clean(newAddress.value.Street)
    newAddress.value.House = clean(newAddress.value.House)
    newAddress.value.Corpus = clean(newAddress.value.Corpus)

    if (appConfig.VueSettingsPreRun.OrderDeliveryTypeEnable) {
      if (
        newAddress.value.OrderDeliveryType === OrderDeliveryType.Flat ||
        newAddress.value.OrderDeliveryType === OrderDeliveryType.Org
      ) {
        newAddress.value.Apartment = clean(newAddress.value.Apartment)
        newAddress.value.Doorway = clean(newAddress.value.Doorway)
        newAddress.value.Intercome = clean(newAddress.value.Intercome)
        newAddress.value.Floor = clean(newAddress.value.Floor)
      } else {
        newAddress.value.Apartment = ''
        newAddress.value.Doorway = ''
        newAddress.value.Intercome = ''
        newAddress.value.Floor = ''
      }
    } else {
      newAddress.value.Apartment = clean(newAddress.value.Apartment)
      newAddress.value.Building = clean(newAddress.value.Building)
      newAddress.value.Doorway = clean(newAddress.value.Doorway)
      newAddress.value.Intercome = clean(newAddress.value.Intercome)
      newAddress.value.Floor = clean(newAddress.value.Floor)
      newAddress.value.Comment = clean(newAddress.value.Comment)
    }

    await (Guid.IsNullOrEmpty(newAddress.value.Id)
      ? accountStore.addSavedAddress(newAddress.value, appConfig.RestaurantSettingsPreRun.OneStageCart)
      : accountStore.changeSavedAddress(
          newAddress.value,
          appConfig.RestaurantSettingsPreRun.OneStageCart
        ))

    popupStore.showSuccess(translate('savedAddressesPopup.success'))
    await popupStore.closePopup()
  } catch (error) {
    await popupStore.closePopup()
    if (error.message) {
      popupStore.showError(error.message)
    } else {
      popupStore.showException(error)
    }
  } finally {
    lockButton.value = false
  }
}

watch(
  () => newAddress.value.CityID,
  (newValue, oldValue) => {
    if (newValue !== oldValue && oldValue !== Guid.Empty) {
      newAddress.value = {
        ...defaultAddress,
        CityID: newValue,
        CloserDepartmentID: newAddress.value.CloserDepartmentID,
        CloserTerminalID: newAddress.value.CloserTerminalID,
        OrderDeliveryType: newAddress.value.OrderDeliveryType
      }
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';

.v-saved-address-popup-title {
  font-size: variables.$TextSizeH3;
  font-weight: 700;
}
</style>
