<template>
  <div :class="classNameComputed">
    <span class="v-col-9 v-switch-label-outside">
      <span v-html="label" />
      <span
        v-if="required"
        class="v-required"
      />
    </span>
    <div class="v-col-3 v-switch">
      <div class="v-switch__container">
        <input
          :id="uid"
          class="v-switch__input"
          :aria-checked="switchValue"
          :aria-label="label.toString()"
          :disabled="disabled"
          :inert="disabled"
          type="checkbox"
          v-model="switchValue"
        />
        <label
          class="v-switch__label"
          :for="uid"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { VElement } from '@arora/common'

const { checked, className, disabled } = defineProps<
  VElement & {
    checked: boolean
  }
>()
const emit = defineEmits(['update:checked'])

const uid = useId()

const switchValue = computed<boolean>({
  get() {
    return checked
  },
  set(value) {
    emit('update:checked', value)
  }
})

const classNameComputed = computed<string>(() => {
  const result = className ?? 'v-row v-align-items-center v-mb-sm'

  return disabled ? `disabled ${result}` : result
})
</script>

<style lang="scss">
@use '~/assets/variables';

// Variables
$bg-disabled-color: rgba(0, 0, 0, 0.2);
$lever-disabled-color: #e2e2e2;

.v-switch {
  position: relative;
  font-size: variables.$TextSizeMain;
  height: 24px;

  &__container {
    position: relative;
  }

  &__input {
    display: none;
  }

  // Unchecked
  &__label {
    display: block;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      right: 0;
      width: 36px;
      height: 14px;
      background-color: $bg-disabled-color;
      border-radius: 14px;
      z-index: 1;
      transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      box-shadow: 0.5px 0.5px 12px 0.5px $bg-disabled-color inset;
    }

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      right: 16px;
      width: 20px;
      height: 20px;
      background-color: $lever-disabled-color;
      border-radius: 14px;
      box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      z-index: 2;
      transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      transition-property: right, background-color;
    }
  }

  // Checked
  &__input:checked + &__label {
    &:before {
      background-color: variables.$PrimaryBackgroundColorLightest;
    }

    &:after {
      right: 0;
      background-color: variables.$PrimaryBackgroundColor;
    }
  }
}
</style>
